(function ($, arc) {

    arc.sota = function ($sota, dialog) {
        return new Sota($sota, dialog);
    };

    // Creates a Sota instance - should should be ran everytime the vehicle details dialog is launched
    function Sota($sota, dialog) {
        // the sota content wrapper element (passed from arc.tabs)
        this.$sota = $sota;
        // we need access to dialog so we can resize it when elements height changes...
        this.dialog = dialog;

        // if vehicle not in session (hows that possible anyway?)
        if (!this.$sota.data('has-vin')) return;
        // eventId is set in this.create
        this.eventId = null;
        this.previousUpdatableModules = null;
        this.updatableModules = null;

        // elements
        this.$table = this.$sota.find('table').hide();
        this.$refresh = this.$sota.find('#refresh-date').hide();
        this.refreshText = this.$refresh.text();
        this.$error = this.$sota.find('#sota-error').hide();
        this.$warning = this.$sota.find('#sota-warning').hide();
        this.$timeout = this.$sota.find('#sota-timeout').hide();
        this.$switch = this.$sota.find("#sota-enroll").hide();
        this.$errorVin = this.$sota.find('#sota-error-vin').hide();
        this.$errorUpdate = this.$sota.find('#sota-update-timeout').hide();
        this.$switchCheckbox = this.$switch.find('input#sota-enroll-checkbox');
        this.$switchSpan = this.$switch.find('span');
        this.$updates = $();
        this.$replaces = $();
        this.$spinner = $('<div/>').addClass('soft-top soft-bottom align-center').append($('<div/>').addClass('loading-spin'));
        this.enrolled = false;

        // if not pilot the plane crashes (switch and all installation status' disabled)
        this.pilot = this.$sota.data("sota-pilot");
        this.statuses = {
            '-1': 'disabled',
            1: 'success',
            2: 'info',
            3: 'warning',
            6: 'important'
        };
        this.historyStatuses = {
            'completed': 'success',
            'deferred': 'warning',
            'failed': 'important'
        };

        // requests gets assigned to ajax request when request in progress - set back to null when request finished
        this.updateRequest = null;
        this.enrollRequest = null;
        this.replaceRequest = null;

        // attach refresh event to warning to force refresh
        this.$warning.children('#sota-refresh-link').on('click', this.refresh.bind(this));

        // create the sota table, attach events to switch and update buttons
        this.addSpinner();
        this.create(true);

    }

    // abort enroll/update/replace requests if dialog was closed
    Sota.prototype.destroy = function () {
        if (this.enrollRequest) this.enrollRequest.abort();
        if (this.updateRequest) this.updateRequest.abort();
        if (this.replaceRequest) this.replaceRequest.abort();

        this.enrollRequest = null;
        this.updateRequest = null;
        this.replaceRequest = null;
    };

    // GET - /vehicle/sota/data
    // request JSON and build tabular data, or show warning/error if response isn't successful
    // POST - /vehicle/sota/tabClick
    // assigns eventId to allow enroll/update to occur
    Sota.prototype.create = function (initial) {
        var self = this, $tbody;
        this.updatableModules = [];

        $.get(arc.defaultContextPath + '/vehicle/sota/data', function (res) {
            self.removeSpinner();
            var state = res.responseState;
            // var state = 'FAILURE';
            self.enrolled = res.sotaEnabled;
            if (state === 'FAILURE') {
                // generic error, details will be logged server-side
                self.show('error');
            } else {
                if (state === 'REFRESH') {
                    // refresh given, show warning to allow user to refresh tab
                    self.show('warning');
                }
                // build tabular data from res.data
                $tbody = $('<tbody/>');

                // loop through each object in res.data array
                $.each(res.data, function (index, row) {
                    // build table row based on current object in res.data
                    var $row = $('<tr/>', {role: 'row'}),
                        $button = row.installationStatus.type === 0 ? (self.pilot && row.installationStatus.enabled) ?
                            $('<div/>').addClass('link').append($('<a/>', {text: row.installationStatus.text}))
                            : $('<div/>').append($('<p/>', {text: row.installationStatus.text})).addClass('uneditable-textarea')
                            : $('<div/>', {text: row.installationStatus.text}),
                        $currentVersion = $('<div/>', {text: row.currentVersion}),
                        $latestVersion = typeof row.latestVersion.id === 'undefined' ?
                            $('<div/>', {text: row.latestVersion.value}) :
                            $('<div/>').addClass('link').data('id', row.latestVersion.id).append($('<a/>', {text: row.latestVersion.value}), $('<i/>').addClass('fa fa-external-link')),
                        setReleaseNotesEvent = function ($element) {
                            var id = $element.data('id');
                            if (typeof id !== 'undefined') {
                                $element.on('click', function () {
                                    // this.link returns document#procedure - build full link
                                    var url = document.location.origin + arc.defaultContextPath + '/content/document/view?id=' + id;
                                    window.open(
                                        url,
                                        '_blank'
                                    );
                                });
                            }
                        },
                        $history = row.installationStatus.type !== -1 ? $('<i/>').addClass('fa fa-history pull-right icon-link').on('click', function () {
                            self.history.create.call(self, $row, $(this), row.moduleName);
                        }) : null;

                    setReleaseNotesEvent($currentVersion);
                    setReleaseNotesEvent($latestVersion);

                    // $button is a badge if not an update
                    if (row.installationStatus.type > 0 || row.installationStatus.type === -1) {
                        $button.addClass('badge badge-text');
                    }

                    if (self.statuses.hasOwnProperty(row.installationStatus.type)) {
                        $button.addClass('badge-' + self.statuses[row.installationStatus.type]);
                    }

                    // Installation Status Type
                    // 0 - UPDATE
                    // 1 - COMPLETE
                    // 2 - READY
                    // 3 - DEFERRED
                    // 4 - FAILED
                    // 5 - CANCELLED
                    // 6 - VERSION MISMATCH
                    // if this row has an Installation Status of UPDATE (type - 0) and button should be enabled
                    // push to updatedModules for tabClick POST line 122
                    // add to $updates jQuery Object to attach click event listener on tabClick POST Success line 136
                    if (row.installationStatus.type === 0 && row.installationStatus.enabled) {
                        self.updatableModules.push(row.moduleName);
                        self.$updates = self.$updates.add($button);
                        // user shouldn't be able to update until eventId has been set, this may already be the case but either way
                        // it will be re-attached later after data has been created.
                        $button.addClass('link-disabled');
                    }

                    var buttonTd = $('<td/>').append(
                        $button
                        // assign data-update and data-module text to $button to be used on update event (on $button click)
                            .data('update', row.installationStatus.text)
                            .data('module', row.moduleName)
                    );
                    //we reach final status(complete, failed deferred), the button to replace should appears.
                    if (row.installationStatus.type === 1 || row.installationStatus.type === 3 || row.installationStatus.type === 4) {
                        var $replaceButton = $('<div/>').addClass('link replace-button').append($('<a/>',
                            {
                                text: $i.i18n.get('vehicle.summary.vehicleSoftware.replace')
                            })).data('module', row.moduleName);
                        buttonTd.append($replaceButton);
                        self.$replaces = self.$replaces.add($replaceButton);
                    }

                    $tbody.append(
                        $row.append(
                            $('<td/>', {text: row.moduleName}),
                            $('<td/>').append($currentVersion),
                            $('<td/>').append($latestVersion),
                            buttonTd,
                            $('<td/>').append($history)
                        )
                    );
                });

                self.$sota.find('tbody').replaceWith($tbody);
                // show at end as we only show table if there is data in it which doesn't happen until the line above!
                self.show();
            }

            // switch exists, check if it the enrollment is true, also disable the switch if enrolled, otherwise not disabled (allow user
            // to enroll)
            if (self.$switchCheckbox.length) {
                if (self.enrolled) {
                    self.activateEnroll();
                    // Temporary condition to only allow enrollment to be turned on and not off - disable switch if vehicle is already
                    // enrolled.
                    self.disableEnroll();
                } else {
                    self.deactivateEnroll();
                    self.enableEnroll();
                }
                if (!self.pilot) {
                    self.disableEnroll();
                }
            }

            // we only do tabClick on initial run
            if (initial) {
                // POST and retrieve eventId
                $.ajax({
                    url: arc.defaultContextPath + "/vehicle/sota/tabClick",
                    method: "POST",
                    // this is to not append [] into array name (modules[]) as struts doesn't support it
                    traditional: true,
                    data: {
                        // if SOTA is enrolled or not
                        "enabled": self.$switchCheckbox.length ? self.$switchCheckbox.prop('checked') : false,
                        // array of moduleName's of modules that have an installationStatus of 0 (UPDATE)
                        "modules": self.updatableModules
                    },
                    success: function (response) {
                        // assign eventId and attach switch and update button events (as these depend on eventId for their requests!)
                        self.eventId = response.id;
                        self.attachEnrollAndUpdate();
                    }
                });
                // not initial run - if previousUpdatableModules is empty but there are new updatableModules
            } else if (self.previousUpdatableModules === [] && self.updatableModules.length) {
                // eventId was already set initially, so we can re-attach enroll and update events instantly.
                self.attachEnrollAndUpdate();

                $.ajax({
                    url: arc.defaultContextPath + "/vehicle/sota/tabClickUpdate",
                    method: "POST",
                    // this is to not append [] into array name (modules[]) as struts doesn't support it
                    traditional: true,
                    data: {
                        // eventId set in initial creation
                        "eventId": self.eventId,
                        // array of moduleName's of modules that have an installationStatus of 0 (UPDATE)
                        "modules": self.updatableModules
                    }
                });
                // always default to attaching enroll/update events back as long as an eventId exists (initial run had ran successfully)
            } else if (typeof self.eventId !== 'undefined') {
                self.attachEnrollAndUpdate();
            }

            // set previous to current updatableModules - so on next run if previous is empty array and there a new updatable
            // modules (successful response with update installationStatus.type = 0 (status) on 1 or more rows
            this.previousUpdatableModules = this.updatableModules;
            return res.data;
        });
    };

    Sota.prototype.attachEnrollAndUpdate = function () {
        // Temporary condition to only allow enrollment to be turned on and not off - don't reattach event if vehicle is already enrolled.
        if (!this.enrolled) {
            this.$switchCheckbox.on('change', this.enroll.bind(this));
        }

        // reattach all update module anchors events
        this.$updates.each(function (i, anchor) {
            $(anchor).removeClass('link-disabled').on('click', this.update.bind(this, $(anchor)));
        }.bind(this));

        // reattach all replace module anchors events
        this.$replaces.each(function (i, anchor) {
            $(anchor).removeClass('link-disabled').on('click', this.replace.bind(this, $(anchor)));
        }.bind(this));
    };

    Sota.prototype.hide = function () {
        this.$error.hide();
        this.$errorVin.hide();
        this.$warning.hide();
        this.$timeout.hide();
        this.$refresh.hide();
        this.$switch.hide();
        this.$table.hide();
    };

    Sota.prototype.show = function (type) {
        this.$refresh.show();
        this.$switch.show();

        switch (type && type.toUpperCase()) {
            case 'ERROR': {
                this.$error.show();
                this.showTableIfHasData();
                break;
            }
            case 'ERROR_VIN': {
                this.$errorVin.show();
                this.showTableIfHasData();
                break;
            }
            case 'UPDATE_ERROR': {
                this.$errorUpdate.show();
                this.showTableIfHasData();
                break;
            }
            case 'WARNING': {
                this.$warning.show();
                this.showTableIfHasData();
                break;
            }
            case 'TIMEOUT': {
                this.$timeout.show();
                this.showTableIfHasData();
                break;
            }
            default: {
                // could have been successful but no vehicle software data, so always only show table if data exists!
                this.showTableIfHasData();
            }
        }

        // update/set the last updated time to now
        this.setTime();
        // dialog doesn't auto resize we have to tell it to.
        this.dialog.resize.call(this.dialog);
    };

    // we only want to show the table if there is data (prevents having empty table with just table headers visible)
    Sota.prototype.showTableIfHasData = function () {
        var self = this;
        if (this.$table.find('tbody > tr').length) {
            this.$table.show(1, function () {
                self.showScrollIndicators();
            });
        }
    };

    // user clicked update anchor for a particular module
    Sota.prototype.update = function ($anchor) {
        var self = this;
        // prevent update if an update, enrollment or replace is already in progress or user is not sota pilot
        if (this.updateRequest || this.enrollRequest || this.replaceRequest || !self.pilot) return;
        this.$errorVin.hide();
        this.$errorUpdate.hide();
        $anchor.addClass('link-disabled');
        this.hide();
        this.addSpinner($anchor.data('update') + ' - ' + $anchor.data('module'));

        this.updateRequest = $.post(
            arc.defaultContextPath + '/vehicle/sota/update',
            {
                "parentEventId": this.eventId,
                "moduleName": $anchor.data('module')
            },
            function (res) {
                self.updateRequest = null;
                $anchor.removeClass("link-disabled");

                self.removeSpinner();

                if (res && res.responseState === 'FAILURE') {
                    self.show('error');
                } else if (res && res.responseState === 'VEHICLE_NOT_MATCH') {
                    self.show('error_vin');
                } else if (res && res.responseState === 'UPDATE_TIMER') {
                    self.show('update_error');
                } else {
                    self.refresh();
                }
            }
        );
    };

    Sota.prototype.replace = function ($anchor) {
        var self = this;
        // prevent update if an update, enrollment or replace is already in progress or user is not sota pilot
        if (this.updateRequest || this.enrollRequest || this.replaceRequest || !self.pilot) return;
        $anchor.addClass('link-disabled');
        this.$errorVin.hide();
        this.$errorUpdate.hide();
        this.hide();
        this.addSpinner($i.i18n.get('vehicle.summary.vehicleSoftware.replace') + ' - ' + $anchor.data('module'));
        this.replaceRequest = $.post(
            arc.defaultContextPath + '/vehicle/sota/replace',
            {
                "parentEventId": this.eventId,
                "moduleName": $anchor.data('module')
            },
            function (res) {
                self.replaceRequest = null;
                $anchor.removeClass("link-disabled");

                self.removeSpinner();

                if (res && (res.responseState === 'SUCCESS' || res.responseState === 'NO_OPERATIONS')) {
                    self.refresh();
                } else if (res && res.responseState === 'VEHICLE_NOT_MATCH') {
                    self.show('error_vin');
                } else {
                    self.show('error');
                }
            }
        );
    };

    Sota.prototype.addSpinner = function (text) {
        if (text) {
            this.$spinner.append($('<span/>').addClass('loading-text').text(text));
        }

        this.$sota.append(this.$spinner);
    };

    Sota.prototype.removeSpinner = function () {
        this.$spinner.find('.loading-text').remove();
        this.$spinner.remove();
    };

    Sota.prototype.enroll = function () {
        var self = this;

        // prevent enrollment if an update, enrollment or replace is already in progress
        if (this.enrollRequest || this.updateRequest || this.replaceRequest) {
            this.deactivateEnroll();
            return;
        }

        // enroll if switch was checked (turned on)
        if (this.$switchCheckbox.is(":checked")) {
            this.disableEnroll();

            // allow switch to finish transition, using jquery deferred we can wait for the setTimeout to finish before we run the sota
            // enroll call
            var deferred = $.Deferred();
            setTimeout(function () {
                self.addSpinner();
                self.hide();
                deferred.resolve();
            }, 300);

            // start enrollment:  Once deferred.resolve has run, jquery deferred will then run the done function.
            deferred.done(
                function () {
                    self.enrollRequest = $.post(arc.defaultContextPath + '/vehicle/sota/enroll', {"parentEventId": self.eventId}, function (res) {
                        var state = res.responseState;
                        self.enrollRequest = null;
                        self.enableEnroll();

                        if (state === 'SUCCESS') {
                            self.refresh();
                        } else if (state === 'TIMEOUT') {
                            self.show('timeout');
                            self.deactivateEnroll();
                        } else if (state === 'ERROR_VIN') {
                            self.show('error_vin');
                            self.deactivateEnroll();
                        } else {
                            self.show('error');
                            self.deactivateEnroll();
                        }

                        self.removeSpinner();
                    });
                }
            );

        } else {
            // sota was turned off - disallow clicking update buttons
            this.$updates.addClass('link-disabled').off('click');
        }
    };

    // hide everything, show spinner, and request new data in the aim of updating the table (via create)
    Sota.prototype.refresh = function () {
        this.hide();
        this.addSpinner();
        // disallow enrollment whilst refreshing,
        // this will be turned back on when init is called through create
        this.$switchCheckbox.off('change');
        this.disableEnroll();

        this.$updates.addClass('link-disabled').off('click');
        this.$updates = $();

        this.$replaces.addClass('link-disabled').off('click');
        this.$replaces = $();

        this.create();
    };

    // set time to last updated field
    Sota.prototype.setTime = function () {
        var d = new Date(),
            h = d.getHours(),
            m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
            s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

        this.$refresh.text(this.refreshText + ' ' + h + ":" + m + ":" + s).removeClass('hide');
    };

    Sota.prototype.history = {
        // creates history table row below the module row triggered
        create: function ($prevRow, $icon, module) {
            var self = this,
                $close = $('<i/>').addClass('icon-link fa fa-times pull-right'),
                $loader = $('<i/>').addClass('icon-loader pull-right'),
                $tbody = $('<tbody/>'),
                $row = $('<tr/>').append($('<td/>', {colspan: 5}).addClass('table-nested').append(
                    $('<table/>').append(
                        $('<thead/>').append(
                            $('<tr/>').append(
                                $('<th/>', {width: '15%', text: arc.i18n.get('vehicle.summary.vehicleSoftware.date')}),
                                $('<th/>', {width: '18%', text: arc.i18n.get('vehicle.summary.vehicleSoftware.type')}),
                                $('<th/>', {width: '17%', text: arc.i18n.get('vehicle.summary.vehicleSoftware.installedSoftware')}),
                                $('<th/>', {width: '19%', text: arc.i18n.get('vehicle.summary.vehicleSoftware.targetSoftware')}),
                                $('<th/>', {width: '15%', text: arc.i18n.get('vehicle.summary.vehicleSoftware.retailer')}),
                                $('<th/>', {width: '16%', text: arc.i18n.get('vehicle.summary.vehicleSoftware.installationStatus')})
                            )
                        ),
                        $tbody
                    )
                )),
                getSoftwareElements = function () {
                    if (!this.value) return null;

                    return this.link ?
                        $('<div/>')
                            .addClass('link')
                            .append(
                                $('<a/>', {text: this.value}),
                                $('<i/>').addClass('fa fa-external-link')
                            ).on('click', function () {
                            // this.link returns document#procedure - build full link
                            var url = document.location.origin + arc.defaultContextPath + '/content/document/view?id=' + this.link;
                            window.open(
                                url,
                                '_blank'
                            );
                        }) :
                        $('<div/>', {text: this.value});
                };

            $icon.replaceWith($loader);

            $.ajax({
                url: arc.defaultContextPath + '/service/vehicle/software/history/' + this.$sota.data('vin') + '/' + module,
                method: "GET",
                success: function (res) {
                    // if server down the builds response there no array given back to client
                    if (!res.softwareHistoryList) {
                        $loader.replaceWith($icon.on('click', self.history.create.bind(self, $prevRow, $icon, module)));
                        return;
                    }

                    if (res.softwareHistoryList.length) {
                        // history data was given, build historic data in table
                        $.each(res.softwareHistoryList, function (index, row) {
                            $tbody.append(
                                $('<tr/>').append(
                                    $('<td/>', {text: row.date}),
                                    $('<td/>', {text: row.type}),
                                    $('<td/>').append(getSoftwareElements.bind(row.installedSoftware)),
                                    $('<td/>').append(getSoftwareElements.bind(row.targetSoftware)),
                                    $('<td/>').append(row.retailer ? row.retailer : '&nbsp;'),
                                    $('<td/>').append(function () {
                                        return $('<div/>', {text: row.status.translation}).addClass(function () {
                                            return 'badge badge-text' + (self.historyStatuses.hasOwnProperty(row.status.status.toLowerCase()) ? ' badge-' + self.historyStatuses[row.status.status.toLowerCase()] : '');
                                        });
                                    })
                                )
                            );
                        });
                    } else {
                        // no history data was given, tell user there's no history recorded
                        $tbody.append(
                            $('<tr/>').append(
                                $('<td/>', {colspan: 6}).addClass('table-watermark').append(
                                    arc.i18n.get('sota.history.no-results')
                                )
                            )
                        );
                    }

                    $loader.replaceWith($close.on('click', self.history.remove.bind(self, $prevRow, $row, $icon, $close, module)));
                    $row.insertAfter($prevRow);
                    // dialog doesn't auto resize we have to tell it to.
                    self.dialog.resize.call(self.dialog);
                }
            });
        },
        // removes the history row (triggered by $close in create method)
        remove: function ($prevRow, $row, $icon, $close, module) {
            $row.remove();
            $close.replaceWith($icon.on('click', this.history.create.bind(this, $prevRow, $icon, module)));
            // dialog doesn't auto resize we have to tell it to.
            this.dialog.resize.call(this.dialog);
        }
    };

    Sota.prototype.enableEnroll = function () {
        this.$switchCheckbox.prop('disabled', false);

        if ($.browser.msie && $.browser.versionNumber === 8) {
            this.$switchSpan.removeClass('input-disabled');
            this.$switchSpan = this.$switchSpan.replaceWith(this.$switchSpan);
        }
    };

    Sota.prototype.disableEnroll = function () {
        this.$switchCheckbox.prop('disabled', true);

        if ($.browser.msie && $.browser.versionNumber === 8) {
            this.$switchSpan.addClass('input-disabled');
            this.$switchSpan = this.$switchSpan.replaceWith(this.$switchSpan);
        }
    };

    Sota.prototype.activateEnroll = function () {
        this.$switchCheckbox.prop('checked', true);

        if ($.browser.msie && $.browser.versionNumber === 8) {
            this.$switchSpan.addClass('input-checked');
            this.$switchSpan = this.$switchSpan.replaceWith(this.$switchSpan);
        }
    };

    Sota.prototype.deactivateEnroll = function () {
        this.$switchCheckbox.prop('checked', false);

        if ($.browser.msie && $.browser.versionNumber === 8) {
            this.$switchSpan.removeClass('input-checked');
            this.$switchSpan = this.$switchSpan.replaceWith(this.$switchSpan);
        }
    };

    Sota.prototype.showScrollIndicators = function () {
        var self = this;

        var scrollLeftVal = 0,
            $scrollIndicators = $('.scroll-indicator');

        //on sota tab click, get table width and wrapper width before scroll and check if table is wider than wrapper. If so display
        // indicators.
        function showIndicators() {
            var tableWidth = $(self.$table).get(0).scrollWidth,
                containerWidth = $(self.$table).parent().outerWidth();

            if (tableWidth > containerWidth) {
                $scrollIndicators.show();
            } else {
                $scrollIndicators.hide();
            }

        }

        showIndicators();

        $(window).resize(function () {
            showIndicators();
        });


        $(this.$table).parent().scroll(function () {

            var $elem = $(self.$table.parent());

            var newScrollLeftPos = $elem.scrollLeft(),
                width = $elem.outerWidth(),
                scrollWidth = $elem.get(0).scrollWidth;


            if (scrollWidth - newScrollLeftPos === width) {
                $scrollIndicators.find('.scroll-left').css('display', 'block');
                $scrollIndicators.find('.scroll-right').css('display', 'none');
            }

            if (newScrollLeftPos === 0) {
                $scrollIndicators.find('.scroll-left').css('display', 'none');
                $scrollIndicators.find('.scroll-right').css('display', 'block');
            }

            scrollLeftVal = newScrollLeftPos;

        });
    };

})(jQuery, arc);
