(function($, arc) {
    arc.bmis = function($bmis, dialog) { return new Bmis($bmis, dialog); };

    // As Built tab constructor
    function Bmis ($bmis, dialog) {
        this.$bmis = $bmis;
        this.dialog = dialog;
        this.$table = this.$bmis.find('table').hide();
        this.create();
    }

    // destroys the tab by cancelling the request if necessary
    Bmis.prototype.destroy = function() {
      if (this.request) this.request.abort();
      this.request = null;
    };

    // requests the data from service and creates table rows
    Bmis.prototype.create = function() {
      var thiz = this;
      this.request = $.get(arc.defaultContextPath + '/service/bmis/getFeaturesList', function(res, textStatus, xhr) {

        var $tbody = thiz.$table.find('tbody');
        $.each(res.feature_states, function(index, row) {
          var $row = $('<tr/>', { role: 'row' });
          $tbody.append(
            $row.append(
              $('<td/>', { text: row['Test type'] }),
              $('<td/>', { text: row['Serial'] }),
              $('<td/>', { text: row['Service time'] }),
              $('<td/>', { text: row['Battery Decision'] }),
              $('<td/>', { text: row['Voltage'] }),
              $('<td/>', { text: row['CCA'] }),
              $('<td/>', { text: row['Rated CCA'] }),
              $('<td/>', { text: row['Battery Temp'] }),
              $('<td/>', { text: row['Test code'] })
            )
          );
        });
        thiz.show();
      });
    };

    // shows the tab & table only if table is not empty
    Bmis.prototype.show = function() {
      if (this.$table.find('tbody > tr').length) {
       this.$table.show();
       $( "#bmis-tab" ).show();
      }
      this.dialog.resize.call(this.dialog);
    };
})(jQuery, arc);
